import React, { useState } from "react";

import { Col, Container, Row, Offcanvas } from "react-bootstrap";

import Anchor from "../elements/Anchor";
import CtaChargespot from "./CtaChargespot";
import CtaCharge from "./CtaCharge";
import MenuItem from "../elements/MenuItem";
import MenuItemDrawer from "../elements/MenuItemDrawer";
import LanguageSwitcher from "../elements/LanguageSwitcher";
import { HumburgerMenu, Logo } from "../../helpers/Icons";
import { siteLang as defaultLanguage } from "../../../config/site-config";

import "../../styles/components/blocks/header.scss";

const Header = ({ settings, activeDocMeta, headerClass }) => {
  const [showDrawer, setShowDrawer] = useState(false);

  const handleDrawerClose = () => setShowDrawer(false);
  const handleDrawerShow = () => setShowDrawer(true);

  const {
    charge_points_label: chargeLabel,
    charge_points_link: chargeLink,
    come_chargespot_label: chargespotLabel,
    come_chargespot_link: chargespotLink,
    body,
  } = settings.data;

  const mainMenu = body.filter((slice) => slice.slice_type === "menu");

  const homepageUrl =
    settings.lang === defaultLanguage
      ? "/"
      : `/${settings.lang.split("-")[0]}/`;

  const mainNav = mainMenu.map((menu) => (
    <MenuItem menu={menu} key={menu.primary.label} />
  ));

  const mainNavDrawer = mainMenu.map((menu) => (
    <MenuItemDrawer menu={menu} key={menu.primary.label} />
  ));

  return (
    <header className={`site-header py-gutter ${headerClass || ""}`}>
      <Container fluid>
        <Row>
          <Col xxl={{ span: 10, offset: 1 }}>
            <div className="site-header__container">
              <Anchor href={homepageUrl} className="site-header__logo">
                <Logo />
              </Anchor>
              {activeDocMeta.type === "homepage" && (
                <h1 className="visually-hidden">
                  {settings?.data?.site_title ||
                    "Chargespot powered by Protergia"}
                </h1>
              )}
              <div className="site-header__content">
                <div className="site-header__navigation">
                  <nav className="site-header__main-menu">{mainNav}</nav>
                  <nav className="site-header__cta-menu">
                    <CtaCharge
                      className="nav-link"
                      data={{ chargeLabel, chargeLink }}
                    />
                    <CtaChargespot
                      className="nav-link"
                      data={{
                        chargespotLabel,
                        chargespotLink,
                      }}
                    />
                  </nav>
                </div>
                <button
                  type="button"
                  className="site-header__drawer-toggle nav-link"
                  onClick={handleDrawerShow}
                >
                  <HumburgerMenu />
                  <span className="visually-hidden">Menu</span>
                </button>
                <LanguageSwitcher activeDocMeta={activeDocMeta} />
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <Offcanvas
        placement="end"
        show={showDrawer}
        onHide={handleDrawerClose}
        className="offcanvas--main-nav bg-dark"
      >
        <Offcanvas.Header closeVariant="white" closeButton>
          <Offcanvas.Title>
            <Anchor href={homepageUrl} className="site-header__logo">
              <Logo />
            </Anchor>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          {mainNavDrawer}
          <hr />
          <CtaCharge
            className="nav-link w-100 mb-2"
            data={{ chargeLabel, chargeLink }}
          />
          <CtaChargespot
            className="nav-link w-100"
            data={{
              chargespotLabel,
              chargespotLink,
            }}
          />
          <hr />
          <LanguageSwitcher activeDocMeta={activeDocMeta} />
        </Offcanvas.Body>
      </Offcanvas>
    </header>
  );
};

export default Header;

